<template>
    <div class="diolgs">
        <el-dialog
            title="设置报警推送"
            :visible.sync="dialogVisible"
            width="950"
            append-to-body
            :before-close="handleClose"
            center
            
            >

            <div class="row_cont">
                <p class="tils">设备</p>
                <div style="display:flex;">
                    <div style="width:290px">
                        可选设备：
                        <el-select v-model="serial_number" style="width:150px" size="small" placeholder="请选择">
                            <el-option :label="item.name" :value="item.serial_number" v-for="item in tabledata" :key="item.id"></el-option>
                        </el-select>

                        <div style="display:flex;margin:20px 0 20px 0">
                            <el-input v-model="serial_number" size="small" placeholder="请输入内容" style="width:150px;margin-right:20px"></el-input>
                            <el-button type="primary" @click="handSerial" size="small">查询</el-button>
                        </div>
                    </div>   
                    <div style="margin-left:90px">
                        信息类型：
                        <el-select v-model="type" style="width:150px" size="small" placeholder="请选择">
                            <el-option label="默认" value="-1"></el-option>
                            <el-option label="语言电话" value="0"></el-option>
                            <el-option label="短信" value="1"></el-option>
                            
                        </el-select>
                        <p style="margin-top:22px">已选设备</p>
                    </div>
                </div>
                <Transtable ref="trantable" @checkRightAll="checkRightAll1" :tabledata="tabledata" :Tables="Tables1"></Transtable>
            
            </div>

            <div class="row_cont" style="margin-top:30px">
                <p class="tils">员工</p>
                <div style="display:flex;">
                    <div style="width:290px">
                        
                        <div style="display:flex">
                            <p>部门：</p>
                            <Select  @handleCheckChange="handleCheckChange" ></Select>
                        </div>

                        <div style="display:flex;margin:20px 0 20px 0">
                            <el-input v-model="input" size="small" placeholder="示例：姓名" style="width:150px;margin-right:20px"></el-input>
                            <el-button type="primary" @click="handEmquer" size="small">查询</el-button>
                        </div>
                    </div>   
                    <div style="margin-left:90px">
                       
                        <p style="margin-top:62px">已选员工</p>
                    </div>
                </div>
                <Transtable ref="trantable2" @checkRightAll="checkRightAll2" :tabledata="depList" :Tables="Tables2"></Transtable>
            
            </div>

       
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="handalrModl">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Transtable from './transtable'
import Select from '@/components/selectree/wltreeselect'
export default {
    components:{
        Transtable,
        Select
    },
    data(){
        return{
            dialogVisible:false,
            UserInfo:JSON.parse(window.localStorage.getItem('user')),
            dept_id:'',
            serial_number:'',
            depList:[],
            tabledata:[],
            data1:[],
            value1:'',
            value2:'',
            input:'',
            type:'-1',
            Tables1:[
                {
                    prop: "name",
                    label: '设备安装位置',
                },
                {
                    prop: "serial_number",
                    label: '设备序列号',
                },
            ],
            Tables2:[
                {
                    prop: "name",
                    label: '姓名',
                },
                {
                    prop: "dept_name",
                    label: '部门',
                },
            ],
            device_id:[],
            user_id:[]
        }
    },
    mounted(){
        // this.hanDevlist()
        // this.handEmplya()
        // this.hanDepid()
    },
    watch:{
        'serial_number':function(old,nwa){
            if(old!=nwa){
                this.hanDevlist()
            }   
        }
    },
    methods:{
        handleClose(){
            this.dialogVisible = false
        },
        handSerial(){
            if(this.serial_number){
                this.hanDevlist()
            }
        },
        // 在线设备列表
        hanDevlist(){
            let data={
                method:'co.ipc.online.device.list',
                manager_id:this.UserInfo.manager_id,
                agent_id:this.UserInfo.agent_id,
                dept_id:this.dept_id,
                serial_number:this.serial_number,
            }
            this.$serve(data).then(res=>{
                if(res.data.code==0){
                    this.tabledata = res.data.data
                }
            })
        },
        // 人员查询
        handEmquer(){
            this.handEmplya()
        },
        // 部门人员列表
        handEmplya(){
            let data={
                method:'co.ipc.employee.list',
                manager_id:this.UserInfo.manager_id,
                agent_id:this.UserInfo.agent_id,
                dept_id:this.dept_id,
                name:this.input
            }
            this.$serve(data).then(res=>{
                if(res.data.code==0){
                    this.depList = res.data.data
                }
            })
        },
        // 获取企业部门id
        handleCheckChange(val){
            let dept_id=[]
            val.forEach(el=>{
                dept_id.push(el.id)
            })
            this.dept_id = dept_id.join(",");
            console.log(`677`,this.dept_id)
        },

        checkRightAll1(val){
            this.device_id=[]
            val.forEach(el=>{
                this.device_id.push(el.id)
            })
        },

        checkRightAll2(val){
            this.user_id=[]
            val.forEach(el=>{
                this.user_id.push(el.id)
            })
        },

        // 报存设置
        handalrModl(){
            let data={
                method:'co.ipc.message.config.save',
                device_id:this.device_id.toString(),
                user_id:this.user_id.toString(),
                type:this.type,
                agent_id:this.UserInfo.agent_id
            }
            console.log(data)
            this.$serve(data).then(res=>{
                if(res.data.code==0){
                    this.$message({
                        message: "保存成功!",
                        type: "success",
                    });
                    this.dialogVisible = false
                }else{
                    this.$message({
                        message: "保存失败!",
                        type: "error",
                    });
                }
            })
        },
        
        


    }
}
</script>
<style lang="less" scoped>
.diolgs{
    height: 600px;
}
.el-dialog__body {
    .row_cont{
        height: 300px;
        border: 1px solid #ccc;
        position: relative;
        padding:30px ;
        .tils{
            width: 80px;
            height: 30px;
            position: absolute;
            left: 10px;
            top: -10px;
            background-color:#fff ;
            text-align: center;
        }
    }
}

.el-dialog__body /deep/ .el-dialog__header{
    border-bottom: 1px solid #ccc;
}
.opSetting{
    text-align: center;
    margin-top: 80px;
}
    
</style>