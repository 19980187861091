<template>
    <div class='demo'>
      <div style="display: flex;">
          <Headbuttom></Headbuttom>
           <el-button type="primary" style="margin-left:20px" @click="onPlus" size="small">
             <i class="el-icon-plus" />新增
            </el-button>
          <div @click="handFromshow"><el-input
          size="small"
          class="dev_dowm"
          placeholder=""
          suffix-icon="el-icon-arrow-down"
          :readonly="true"
          >
        </el-input>
        </div>
       
      </div>

      <div class="attheadfrom" v-if="flag">
      <el-form
        :inline="true"
        size="small"
        :model="searchParams"
        class="demo-form-inline"
      >
        
        <el-form-item label="设备序列号">
          <el-input
            v-model="searchParams.serial_number"
          ></el-input>
        </el-form-item>
        <el-form-item label="信息类型">
          <el-select v-model="searchParams.type" placeholder="">
            <el-option label="默认" value="-1"></el-option>
            <el-option label="语言电话" value="0"></el-option>
            <el-option label="短息" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input
            v-model="searchParams.name"
            
          ></el-input>
        </el-form-item>

        <el-form-item label="部门">
         <Select @handleCheckChange="handleCheckChange" ></Select>
        </el-form-item>
        
        <el-form-item>
          <el-button type="primary" @click="onSubmit">{{
            $t("devtable.cx")
          }}</el-button>
        </el-form-item>
      </el-form>
      </div>

      <Tables @handlerow="handlerow"
      @handlpages="handlpages"
      @handleSizeChange="handleSizeChange"
      :tableLabel="tableLabel"
      :tableData="tableData"
      :flags="flags"
      :operation="operation"
      :configs="configs"></Tables>

      <Alarmpdialg ref="alarmpdialog"></Alarmpdialg>

    </div>
</template>

<script>
import Tables from '@/components/table'
import {formatDate} from '../../utils/date'
import Alarmpdialg from './commponents/alarmpdialog'
import Select from '@/components/selectree/wltreeselect'
export default {
    components:{
        Tables,
        Alarmpdialg,
        Select
    },
    data(){
        return{
           flag:false,
           searchParams:{
             name:'',
             serial_number:'',
             dept_id:'',
             type:'-1',
           },
          UserInfo:JSON.parse(window.localStorage.getItem('user')),
          tableLabel:[
            
            {
              prop: "device_name",
              label: '设备安装位置',
              align: "center",
            },
            {
              prop: "serial_number",
              label: '设备序列号',
              align: "center",
            },
            {
              prop: "user_name",
              label: '姓名',
              align: "center",
            },
            {
              prop: "dept_name",
              label: '部门',
              align: "center",
            },
            {
              prop: "type",
              label: '推送消息类型',
              align: "center",
            },
            
          ],
          tableData:[],
          operation:{
            width:100,
            data:[
              {
              name: "删除",
                type: "danger",
              },
              
            ]
          },
          // 分页数据
          configs: {
            page_index:1,
            pagesize: 10,
            total: 200,
            loading: false, // 加载状态
          },
          flags: {
            show: false, // 多选是否显示
            ishow: true, // 序号是否显示
          },
        }
    },

    mounted(){
      this.GetreDprodata()
    },
    methods:{
      handFromshow () {
        this.flag = !this.flag
      },
      onSubmit(){
        this.GetreDprodata()
      },
      // 获取企业部门id
      handleCheckChange(val){
          let dept_id=[]
          val.forEach(el=>{
              dept_id.push(el.id)
          })
          this.searchParams.dept_id = dept_id.join(",");
          console.log(`677`,this.searchParams.dept_id)
      },
      // 新增
      onPlus(){
        this.$refs.alarmpdialog.dialogVisible = true
        this.$refs.alarmpdialog.hanDevlist()
        this.$refs.alarmpdialog.handEmplya()
      },
      
      // 列表数据
      GetreDprodata(){

        let data={
          method:'co.ipc.message.config.list',
          page_index:this.configs.page_index,
          page_size:this.configs.page_size,
          agent_id:this.UserInfo.agent_id,
          name:this.searchParams.name,
          dept_id:this.searchParams.dept_id,
          serial_number:this.searchParams.serial_number,
          type:this.searchParams.type
        }
        console.log(data)
        this.$serve(data).then(res=>{
          if(res.data.code==0){
            this.tableData = res.data.data
            this.tableData.forEach(el=>{
               el.type = el.type=='0' ? el.type = '语言电话':'短信'
            })
            this.configs.total = res.data.count
          }
        })
      },
      // 表格按钮
      handlerow(v,name){
        if(name){
          this.$confirm('是否删除', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let data={
              method:'co.ipc.message.config.del',
              id:v.id
            }
            this.$serve(data).then(res=>{
              if(res.data.code==0){
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
                this.GetreDprodata()
              }else{
                this.$message({
                  type: 'error',
                  message: '删除失败!'
                });
              }
            })

            
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });          
          });
        }
      },
      // 分页
      handlpages(){

      },
      handleSizeChange(){

      }
    }
}
</script>
<style scoped>
.demo{
    background-color: #fff;
    padding: 20px;

}
.dev_dowm{
    margin-left: 30px;
}
.attheadfrom {
    padding: 20px;
    margin-top: 10px;
  }
</style>